<template>
  <a-layout>
    <div class="nav">
      <Breadcrumb />
    </div>
    <div class="card__container">
      <div class="retailer_container">
        当前渠道终端:
        <a-select
          placeholder="请选择渠道终端"
          v-model="curDistributorId"
          @change="onDistributorChanged"
          style="width: 200px"
        >
          <a-select-option
            :value="item.distributorId"
            v-for="item in retailerList"
            :key="item.distributorId"
          >
            {{ item.retailerName }}
          </a-select-option>
        </a-select>
      </div>
      <a-table
        :data-source="tblData"
        :columns="tblColumns"
        :loading="loading"
        :pagination="pagination"
        rowKey="siteId"
        @change="onPage"
      >
        <template slot="time" slot-scope="text, record">
          {{ record.modifiedTime ? tsFormat(record.modifiedTime) : "--" }}
        </template>
        <template slot="action" slot-scope="text, record">
          <a @click="onSetFence(record)">设置电子围栏</a>
        </template>
      </a-table>
    </div>
  </a-layout>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb.vue";
import { fetchLeaseZones } from "@/services/OperationService";
import { fetchMyRetailerList } from "@/services/RetailerService";
import { tsFormat } from "@/components/DateUtils";

const tblColumns = [
  {
    title: "站点名称",
    dataIndex: "siteName",
    width: "40%"
  },
  {
    title: "最后修改时间",
    scopedSlots: { customRender: "time" },
    width: "30%"
  },
  {
    title: "操作",
    scopedSlots: { customRender: "action" },
    width: "30%"
  }
];

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      tblData: [],
      tblColumns,
      retailerList: [],
      curDistributorId: "",
      loading: false,
      pagination: {
        showQuickJumper: true,
        hideOnSinglePage: true
      }
    };
  },
  mounted() {
    this.loadRetailerList();
  },
  methods: {
    tsFormat,
    // 加载渠道终端数据
    loadRetailerList() {
      this.loading = true;
      fetchMyRetailerList()
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            this.retailerList = resp.data.data;
            if (this.retailerList && this.retailerList.length > 0) {
              this.curDistributorId = this.retailerList[0].distributorId;
              this.loadSiteList();
            }
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 切换渠道终端
    onDistributorChanged() {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.loadSiteList();
    },
    // 分页
    onPage(pagination) {
      this.pagination.current = pagination.current;
      this.loadSiteList();
    },
    // 加载数据
    loadSiteList() {
      this.loading = true;
      const params = {
        distributorId: this.curDistributorId,
        pageNum: this.pagination.current ? this.pagination.current : 1,
        pageSize: this.pagination.pageSize ? this.pagination.pageSize : 10
      };
      fetchLeaseZones(params)
        .then(resp => {
          this.loading = false;
          if (resp.data.code === "SUCCESS") {
            const data = resp.data.data;
            this.tblData = data.records;
            const pagination = { ...this.pagination };
            pagination.current = data.pageNum;
            pagination.total = data.total;
            pagination.pageSize = data.pageSize;
            pagination.showTotal = function(total, range) {
              return `当前显示 ${range[0]} - ${range[1]} 条, 共 ${total} 条`;
            };
            this.pagination = pagination;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    // 设置电子围栏
    onSetFence(item) {
      this.$router.push({
        name: "SetFence",
        params: { areaId: item.areaId },
        query: { lng: item.siteLongitude, lat: item.siteLatitude }
      });
    }
  }
};
</script>

<style scoped>
.card__container {
  min-height: 80vh;
}
.retailer_container {
  margin-bottom: 10px;
}
</style>
