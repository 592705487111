import { apiClient } from "@/services/HttpService";

export function fetchLeaseZones(params) {
  return apiClient.get(
    `/operate/area?pageSize=${params.pageSize}&pageNum=${params.pageNum}&distributorId=${params.distributorId}`
  );
}

export function queryOperationArea(areaId) {
  return apiClient.get(`/operate/area/${areaId}`);
}

export function editOperationArea(params) {
  return apiClient.put(`/operate/area`, params);
}

// 根据运营区域查询详情
export function operateAreaDetail(areaId) {
  return apiClient.get(`/operate/area/${areaId}/detail`);
}
